import { colors } from '../../style/variables';
import styled, { css } from 'styled-components';

export const WhiteButton = styled.button<{ 
    width?: number, 
    height?: number, 
    size?: 'regular' | 'sm',
    bgColor?: string
}>`
    outline: none;
    display:flex;
    align-items:center;
    justify-content:center;
    /* text-transform:uppercase; */
    background: #FFFFFF;
    box-shadow: 0px 0px 30px #0000000D;
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    text-transform: auto;
    font-size: 12.5px;
    color: #37474F;
    /* letter-spacing: 0.1rem; */
    text-align: center;
    font-weight:bold;
    transition: background-color .3s ease;    
    width:${({ width }) => width + '%' ?? '100%'};
    height: 40px;
    padding: 15px;
    .icon{
        color: #37474F;
        margin-right:10px;
        font-size:20px;
        display:inline;
        &.egg-color{
            color:${colors.eggColor}
        }
    }
    .vote-icon{
        &.active{
            color:${colors.eggColor};
        }
    }
    &:hover:enabled{
        background-color: ${ props => props.voted ? colors.darkGreyEgg : '#EAEAEA' } ;
        color:${colors.darkGreyEgg};
        cursor:pointer;
        .icon{
            color:${colors.darkGreyEgg};
        }
    }
    &:disabled{
        opacity:0.5;
    }
    ${({ active }) => active && css`
        background-color:${colors.eggColor};
        background-image: linear-gradient(180deg, #FFE800 0%, #FFCD00 100%);
        color:${colors.darkGreyEgg};
        .icon{
            color:white !important;
        }
        &:disabled{
            opacity:1;
        }
    `}
    ${({ bgColor }) => bgColor && css`
            background-color:${bgColor} !important;
            color:white;
    `}
`