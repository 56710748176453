import React, { useState } from 'react'
import { ConfirmationFeedback } from '../../components/Feedback/ConfirmationFeedback';
import { CommentsFeedback } from '../../components/Feedback/CommentsFeedback';
import { SelectorFeedback } from '../../components/Feedback/SelectorFeedback';
import styled, { css } from 'styled-components';


export const OPTIONS = {
    SELECTOR: 'selector',
    COMMENTS: 'comments',
    CONFIRMATION: 'confirmation'
}

export const HAND = {
    UP: 'handUp',
    DOWN: 'handDown'
}

export interface IOptions {
    page: string,
    selection: string
    descriprion: string
}

export const FeedbackView = () => {

    const [option, setOption] = useState<IOptions>({
        page: 'selector',
        selection: '',
        descriprion: ''
    })

    /**
     * If the option is selector, return the selector feedback, if the option is comments, return the
     * comments feedback, if the option is confirmation, return the confirmation feedback, otherwise
     * return the selector feedback.
     * @returns A component
     */
    const renderFeedback = () => {
        switch( option.page ){

            case OPTIONS.SELECTOR : 
               return <SelectorFeedback setOption={ setOption } />

            case OPTIONS.COMMENTS: 
                return <CommentsFeedback option={ option }  setOption={ setOption }/>
            
            case OPTIONS.CONFIRMATION : 
                return <ConfirmationFeedback />

            default:
               return <SelectorFeedback setOption={ setOption } />

        }
    }
   
    
  return (
    <Layout>
        {
            renderFeedback()
        }
    </Layout>
  )
}


const Layout = styled.div<{ centered: boolean, started: boolean }>`
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    z-index: 10;
    justify-content: center;
    
`


