import styled, { css } from "styled-components";
import { colors } from '../../style/variables'

export const MainTitle = styled.h2`
    font-size:20px;
    line-height: 1.3rem;
    color: ${colors.darkGreyEgg};
    
    a{
        color: ${colors.darkGreyEgg};
        text-decoration:none;
        font-weight:bold !important;    
    }

    .title-heading:hover {
        text-decoration: underline;
    }   

`

export const SecondaryTitle = styled(MainTitle)`
    font-size:16px;
`

export const TextBlock = styled.p`
    font-size:12px;
    line-height:19px;
    color: ${colors.darkGreyEgg};
    ${({ center }) => center && css`
        text-align:center;
    `}
`

export const LeadTextBlock = styled(TextBlock)`
    /* font-size:14px; */
    font-size: 12px;
    line-height:20px;
`