import MainLayout from '../../layouts/MainLayout';
import SecondaryHeading from '../../components/SecondaryHeading';
import StatsResume from '../../components/StatsResume';
import { useContext, useEffect, useState } from 'preact/hooks';
import { AppContext } from '../../app';
import { TablePos } from '../../interfaces/IWidgetInfo'
import ReactGA from 'react-ga4'
import { Text } from 'preact-i18n'
import { ellipsis, isAllowedToBeFacilitator } from '../../helpers/Tools'
import { useParams } from 'react-router'
import { UIContext } from '../../context/UIContext'
import { useNavigation } from '../../hooks/useNavigation'

const UserView = () => {
    const { id } = useParams()
    const { widgetInfo } = useContext(AppContext)
    const { setRequestModal } = useContext(UIContext)
    const [selectedUser, setSelectedUser] = useState<TablePos | undefined>(undefined)
    const { navigation } = useNavigation()

    useEffect(() => {
        // Filter this user from widgetInfo table positions
        const user: TablePos[] | undefined = widgetInfo?.table?.positions.filter(student => student._userId._id === id)
        if (user) {
            // Set it local
            setSelectedUser(user[0])
        }
    }, [])

    // Checks if widget user voted this particular user and pass down a bool to mark button as active
    const didIVoteThisUser = () => {
        return widgetInfo?.me.voteData._profileToId === selectedUser?._profileId
    }

    const isMe = () => {
        return widgetInfo?.me.profile._id === selectedUser?._profileId || window.location.pathname.split('/')[2] === widgetInfo?.me._id
    }

    useEffect(() => {
        if (widgetInfo && selectedUser) {
            isMe()
                ? ReactGA.event({ category: 'Profile', action: 'OwnProfileViewed' })
                : ReactGA.event({ category: 'Profile', action: 'TeammateProfileViewed' })
        }
    }, [widgetInfo, selectedUser])

    useEffect(() => {
        if (widgetInfo?.table?.helpRequest?.status === 'mentor-selected') {
            setRequestModal(() => {
                navigation('/')
                return 'mentor-selected'
            })
        }
    }, [widgetInfo?.table?.helpRequest])

    return (
        <MainLayout>
            <SecondaryHeading
                name={
                    isMe() ? (
                        <span style={{ textTransform: 'uppercase', fontSize: 12 }}>
                            <Text id="profile.my_profile" />
                        </span>
                    ) : (
                        <span style={{ textTransform: 'uppercase', fontSize: 12 }}>
                            {ellipsis(selectedUser?._userId.lastname + ' ' + selectedUser?._userId.name, 20)}
                        </span>
                    )
                }
            />
            {selectedUser && (
                <StatsResume
                    isMe={isMe()}
                    isUserVoted={didIVoteThisUser()}
                    isUserFacilitator={widgetInfo?.table?.facilitator === selectedUser?._profileId && isAllowedToBeFacilitator(widgetInfo)}
                    user={selectedUser}
                />
            )}
            {isMe() && !widgetInfo?.table && (
                <StatsResume
                    isMe={true}
                    isUserVoted={false}
                    isUserFacilitator={widgetInfo?.table?.facilitator === selectedUser?._profileId && isAllowedToBeFacilitator(widgetInfo)}
                    user={{
                        number: 0,
                        _profileId: widgetInfo?.me.profile._id || '',
                        _userId: {
                            name: widgetInfo?.me.name || '',
                            lastname: widgetInfo?.me.lastname || '',
                            urlImage: widgetInfo?.me.urlImage || '',
                            _id: widgetInfo?.me._id || '',
                        },
                    }}
                />
            )}
        </MainLayout>
    )
}

export default UserView
