import styled from 'styled-components'
import { MainTitle } from './Type/Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '../components/Buttons/IconButton'
import { useNavigation } from '../hooks/useNavigation'
import { useContext } from 'preact/hooks'
import { UIContext } from '../context/UIContext'

interface IProps {
    name?: string | JSX.Element
}

const SecondaryHeading = ({ name }: IProps) => {
    const { navigation } = useNavigation()
    const { setShowStories } = useContext(UIContext)
    return (
        <Heading>
            <IconButton aria-label="Go back" 
                onClick={() => {
                    setShowStories(false)
                    navigation('/')
                }} 
                className="left">
                <FontAwesomeIcon  icon={['fal', 'arrow-left']} />
            </IconButton>
            <MainTitle className="middle">{name}</MainTitle>
            <IconButton aria-label="Disabled and hidden button" onClick={() => console.log('do nothing')} className="right"></IconButton>
        </Heading>
    )
}

export default SecondaryHeading

const Heading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 21px;
    margin-top: 23px;
    height: 35px;

    .left {
        width: 15%;
        color: #003750;
    }
    .middle {
        width: 70%;
        padding: 0 20px;
        text-transform: capitalize;
        line-height: 1.6rem;
        text-align: center;
    }
    .right {
        width: 15%;
    }
`
