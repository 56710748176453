import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect } from 'preact/hooks'
import { AppContext } from '../../app'
import { HelpRequestStatus, IWidgetTable } from '../../interfaces/IWidgetInfo'
import styled from 'styled-components'
import { Text, MarkupText } from 'preact-i18n'
import { UIContext } from '../../context/UIContext'
import { capitalizeFullName } from '../../helpers/capitalize'

interface IProps {
    helpRequest: IWidgetTable['helpRequest']
    cancelHelpRequest: () => void
}

const HelpRequestNotification = ({ helpRequest, cancelHelpRequest }: IProps) => {
    const { widgetInfo } = useContext(AppContext)
    const { requestNotification, setRequestNotification } = useContext(UIContext)

    const clearNotification = () => {
        setRequestNotification(undefined)
    }

    useEffect(() => {
        if (widgetInfo?.table?.helpRequest) {
            switch (widgetInfo.table.helpRequest.status) {
                case HelpRequestStatus.REQUESTED:
                    setRequestNotification({ status: 'requested' })
                    return
                case HelpRequestStatus.NO_MENTOR_AVAILABLE:
                    setRequestNotification({ status: 'no-mentor-available' })
                    return
            }
        }
    }, [])

    const getCancellationCopy = () => {
        if (widgetInfo?.table?.helpRequest?.from._profileId === widgetInfo?.me.profile._id) {
            return <MarkupText id="help-request.notifications.cancel.canceledByMe" />
        } else {
            return (
                <MarkupText
                    id="help-request.notifications.cancel.canceledBySomeone"
                    fields={{
                        fullName: capitalizeFullName(
                            `${widgetInfo?.table?.helpRequest?.from._userId?.name} ${widgetInfo?.table?.helpRequest?.from._userId?.lastname}`
                        ),
                    }}
                />
            )
        }
    }

    if (requestNotification?.status === 'requested') {
        if (widgetInfo?.me.profile._id === helpRequest?.from._profileId) {
            return (
                <Notification style={{ padding: '12px 12px' }} mode={requestNotification.status}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center ' }}>
                        <div>
                            <p style={{ margin: 0, marginBottom: 2 }} className="title">
                                <strong>
                                    <Text id="help-request.notifications.requested.title" />
                                </strong>
                            </p>

                            <div className="copy">
                                <Text id="help-request.notifications.requested.copy" />
                            </div>
                        </div>

                        <CloseSection>
                            <div style={{ height: 28, display: 'flex', alignItems: 'center' }} className="link" onClick={cancelHelpRequest}>
                                <Text id="help-request.notifications.requested.cta" />
                            </div>
                        </CloseSection>
                    </div>
                </Notification>
            )
        } else {
            return (
                <Notification style={{ padding: '12px 12px' }} mode={requestNotification.status}>
                    <div>
                        <p style={{ margin: 0, marginBottom: 2 }} className="title">
                            <span style={{ textTransform: 'capitalize' }}>
                                {`${helpRequest?.from._userId?.name} ${helpRequest?.from._userId?.lastname} `}
                            </span>
                            <Text id="help-request.notifications.requested.title2" />
                        </p>
                    </div>
                    <div className="copy">
                        <MarkupText id="help-request.notifications.requested.copy" />
                    </div>
                </Notification>
            )
        }
    }

    if (requestNotification?.status === 'mentor-joined') {
        return (
            <Notification mode={requestNotification.status}>
                {/* <div><span className="title"><strong><Text id="help-request.notifications.in-progress.title" /></strong></span></div> */}
                <div className="cancel-notif">
                    <div className="copy">
                        <MarkupText
                            id="help-request.notifications.mentor-joined.copy"
                            fields={{
                                mentorName: capitalizeFullName(
                                    `${requestNotification.payload.payload?.profile?._userId.name} ${requestNotification.payload.payload?.profile?._userId.lastname}`
                                ),
                            }}
                        />
                    </div>
                    <button onClick={clearNotification} aria-label="Clear notification">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </button>
                </div>
            </Notification>
        )
    }

    if (requestNotification?.status === 'last-mentor-left') {
        return (
            <Notification mode={requestNotification.status}>
                {/* <div><span className="title"><strong><Text id="help-request.notifications.in-progress.title" /></strong></span></div> */}
                <div className="cancel-notif">
                    <div className="copy">
                        <MarkupText id="help-request.notifications.mentor-left.copy" />
                    </div>
                    <button onClick={clearNotification} aria-label="Clear notification">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </button>
                </div>
            </Notification>
        )
    }

    if (requestNotification?.status === 'mentor-help') {
        return (
            <Notification mode={requestNotification.status}>
                <div className="cancel-notif">
                    <div className="copy">
                        <MarkupText id="help-request.notifications.mentor-help.copy" />
                    </div>
                    <button onClick={clearNotification} aria-label="Clear notification">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </button>
                </div>
            </Notification>
        )
    }

    if (requestNotification?.status === 'canceled') {
        return (
            <Notification mode={requestNotification.status} style={{ padding: '12px 12px' }}>
                <div className="cancel-notif">
                    <div className="copy">{getCancellationCopy()}</div>
                    <button onClick={clearNotification} aria-label="Clear notification">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </button>
                </div>
            </Notification>
        )
    }

    if (requestNotification?.status === 'ended') {
        return (
            <Notification mode={requestNotification.status} style={{ padding: '12px 12px' }}>
                <div className="cancel-notif">
                    <div className="copy">
                        <MarkupText id="help-request.notifications.ended.title" />
                    </div>
                    <button onClick={clearNotification} aria-label="Clear notification">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </button>
                </div>
            </Notification>
        )
    }

    if (requestNotification?.status === 'professor-is-coming') {
        return (
            <Notification mode={requestNotification.status} style={{ padding: '5px 10px' }}>
                <div className="cancel-notif">
                    <div className="copy">
                        <MarkupText id="help-request.notifications.professor-is-coming.title" />
                    </div>
                    <button onClick={clearNotification} aria-label="Clear notification">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </button>
                </div>
            </Notification>
        )
    }

    if (requestNotification?.status === 'mentor-rejected') {
        return (
            <Notification mode={requestNotification.status} style={{ padding: '5px 10px' }}>
                <div className="cancel-notif">
                    <div className="copy">
                        <MarkupText id="help-request.notifications.mentor-rejected.title" />
                    </div>
                    <button onClick={clearNotification} aria-label="Clear notification">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </button>
                </div>
            </Notification>
        )
    }

    if (requestNotification?.status === 'hr-expired') {
        return (
            <Notification mode={requestNotification.status} style={{ padding: '5px 10px' }}>
                <div className="cancel-notif">
                    <div className="copy">
                        <MarkupText id="help-request.notifications.hr-expired.title" />
                    </div>
                    <button onClick={clearNotification} aria-label="Clear notification">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </button>
                </div>
            </Notification>
        )
    }

    if (requestNotification?.status === 'no-mentor-available') {
        return (
            <Notification mode={requestNotification.status} style={{ padding: '5px 10px' }}>
                <div className="cancel-notif">
                    <div className="copy">
                        <MarkupText id="help-request.notifications.no-mentor-available.title" />
                    </div>
                    <button onClick={clearNotification} aria-label="Clear notification">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </button>
                </div>
            </Notification>
        )
    }

    return null
}

export default HelpRequestNotification


const Notification = styled.div<{ mode: 'requested' | 'canceled' | 'mentor-joined' }>`
    background-color: #003750;
    border-radius: 8px;
    padding: 8px 10px;
    margin-bottom: 10px;
    color: white;
    .title {
        font-size: 11px;
        /* font-weight: bold; */
    }
    .link {
        font-size: 11px;
        text-decoration: underline;
        margin-left: 10px;
        cursor: pointer;
    }
    .copy {
        font-size: 11px;
        line-height: 15px;
        padding-right: 15px;
    }
    .cancel-notif {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            background: none;
            color: white;
            border: none;
            cursor: pointer;
            font-size: 18px;
            &:hover {
                color: #eaeaea;
            }
            padding: 0;
            margin: 0;
        }
    }
`
const CloseSection = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #707070;
    height: 70%;
    color: #fff;
    font-size: 24px;
    /* margin-left: 1em; */
`
