import { useLayoutEffect, useState } from 'preact/hooks'
import styled from 'styled-components'

interface Props {
    percent: number,
    index: number,
    numberStory: number
}

export const ProgressBar = ({ percent, index, numberStory }: Props) => {
    const [progress, setprogress] = useState('')

    useLayoutEffect(() => {
        if (percent < 95 && index === numberStory) {
            setprogress(percent.toString() + '%')
        } else if (percent > 95 && index === numberStory) {
            setprogress('100%')
        } else if (numberStory > index) {
            setprogress('100%')
        } else {
            setprogress('0%')
        }
    }, [percent])

    return (
        <ProgressBarItem>
            <span style={{ height: '3px', backgroundColor: '#00A3FF', width: progress, display: 'block', transition: 'all 0.4s ease-out;' }}></span>
        </ProgressBarItem>
    )
}

const ProgressBarItem = styled.span`
    background: #d5d5d5;
    width: 100%;
    height: 3px;
    border-radius: 40px;
    span {
        -webkit-transition: width 0.2s ease-in-out;
        -moz-transition: width 0.2s ease-in-out;
        -o-transition: width 0.2s ease-in-out;
        transition: width 0.2s ease-in-out;
    }
`

