import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledIconBell } from './StyledIconBell'
 
interface Props {
  onClick?: () => void,
  hasNotifications: boolean
}

export const IconBell = ({ onClick , hasNotifications }: Props) => {
  return (
    <StyledIconBell onClick={ onClick } >
      <FontAwesomeIcon style={{ width: 16, height: 18 }} icon={['fal', 'bell']} />
      {
        hasNotifications && ( <FontAwesomeIcon  className="notifications" icon={['fas','circle']} />)
      }
    </StyledIconBell>
  )
}
