import MainLayout from '../../layouts/MainLayout'
import { useContext, useEffect, useState } from 'preact/hooks'
import styled from 'styled-components';
import { colors } from '../../style/variables';
import AvatarEgg from '../../components/Avatar/AvatarEgg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteNotificationsModal } from '../../components/Actions/DeleteNotificationsModal';
import bellListEmpty from '../../assets/notifications/bell-list-emty.png'
import axios from 'axios'
import { UIContext } from '../../context/UIContext';
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { NotificationsHeading } from '../../components/Notifications/NotificationsHeading';
import { capitalizeFullName } from '../../helpers/capitalize';
import { AppContext } from '../../app';
import { Text } from 'preact-i18n';
import { getElapsedTime } from '../../helpers/Tools';
import { setGAWidgetNotification } from '../../helpers/GAnalytics';
import { useNavigation } from '../../hooks/useNavigation'

dayjs.extend(isToday)

export type Response = {
    notifications: Array<Notifications>
};

export type Notifications = {
    _id: string,
    fromId: string,
    toId: string,
    readStory: boolean,
    tableNumber?: number, 
    user: {
        name: string,
        lastname: string,
        imgUrl: string,
        role: string
    },
    createdAt: Date
}


const NotificationsView = () => {

    const { setNotifications, notifications, setHasNotifications, setRequestModal } = useContext(UIContext)
    const { updateInfo, widgetInfo } = useContext(AppContext)
    const [loading, setLoading] = useState(false)
    const { navigation } = useNavigation()

    const handleGetNotifications = async () => {
        setLoading(true)

        const { data }: { data: { data: Response } } = await axios.get(`${process.env.PREACT_APP_WIDGET_API}/v1/notification/type/all`)

        const notificationsOrdered = data.data.notifications.sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1))
        setNotifications(notificationsOrdered)
        setLoading(false)
    }

    useEffect(() => {
        handleGetNotifications()
        updateInfo({ me: { ...widgetInfo?.me, unreadNotifications: false } })
        setHasNotifications(false)
        setGAWidgetNotification()
    }, [])

    useEffect(() => {
        updateInfo({ me: { ...widgetInfo?.me, unreadNotifications: false } })
        setHasNotifications(false)
    }, [notifications])

    useEffect(() => {
        if (widgetInfo?.table?.helpRequest?.status === 'mentor-selected') {
            setRequestModal(() => {
                navigation('/')
                return 'mentor-selected'
            })
        }
    }, [widgetInfo?.table?.helpRequest])
    
    
    const NotificationItemUser = (item: Notifications) => {
        return (
            <NotificationItem>
                <div className="indicator">
                    <AvatarEgg 
                    size={40} 
                    title={`${ item.user.name } ${ item.user.lastname }`} 
                    src={item.user.imgUrl} 
                    facilitator={item.user.role.toLocaleLowerCase() === 'facilitator'}
                    isMentor={item.user.role.toLocaleLowerCase() === 'mentor'}
                    />
                </div>
                <div className="message">
                <strong style={{ color:'#003750'}}>{`${ capitalizeFullName(item.user.name) } ${ capitalizeFullName(item.user.lastname) }` }</strong>&nbsp;
                <FontAwesomeIcon style={{color:'#003750'}} icon={['fas', 'bolt']} />&nbsp;
                <span style={{ color:'#003750'}}>{
                    item.tableNumber !== null ? <Text id={'notifications.pulse-team'}></Text> : <Text id={'notifications.single-pulse'}></Text> 
                } </span>
                {
                    item.tableNumber !== null ? ' ' : <br />
                }
                    <span className="timestamp">{ getElapsedTime(item.createdAt) }</span>
                </div>
            </NotificationItem>
        )
    }

    return (
        <MainLayout>
            <NotificationsHeading name={ <span style={{ fontSize: 12}}><Text id={'notifications.title'}></Text></span>} />
            {
                loading && <div style={{ textAlign: 'center', height: '100%', padding: '0 40px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <Text id="control.loading" />
                    </div>
                </div>

            }
            {
                !loading &&
                <NotificationsContainer>
                {
                    notifications.length > 0 
                        ? (
                            <NotificationsList>

                                {
                                    notifications.filter((item) => dayjs(item.createdAt).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ).length > 0
                                        ?   <h3 style={{ fontSize: 14 }}> <Text id={'notifications.today'}></Text></h3> : ''
                                }

                                {
                                    notifications.filter((item) => dayjs(item.createdAt).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ).map((item) => (
                                        NotificationItemUser(item)
                                    ))
                                }

                                {
                                    notifications.filter((item) => dayjs(item.createdAt).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')).length > 0
                                        ?   <div>
                                                <hr style={{ backgroundColor: '#efeded', border: 'none', height: 1 }} />
                                            </div>
                                        : ''
                                }
                                
                                
                                {
                                    notifications.filter((item) => dayjs(item.createdAt).format('YYYY-MM-DD') !== dayjs().format('YYYY-MM-DD')).length > 0 
                                        ? <h3 style={{ fontSize: 14 }}><Text id={'notifications.this-week'}></Text></h3> : ''
                                }
                                
                                {
                                    notifications.filter((item) => dayjs(item.createdAt).format('YYYY-MM-DD') !== dayjs().format('YYYY-MM-DD')).map((item) => (
                                        NotificationItemUser(item)
                                    ))
                                }

                                <div style={{ paddingTop: '10px', paddingBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                                    <h3 style={{ fontSize: 10, color: '#A3A3A3' }}>
                                        <Text id={'notifications.no-more-notification'}></Text>
                                    </h3>
                                </div>
                                
                            </NotificationsList>
                        )
                    : (
                        <NotificationEmptyContainer>
                            <img style={{ width: 121 }} src={bellListEmpty} alt="image state empty" />
                            <Subtitle>Sin notificaciones</Subtitle>
                        </NotificationEmptyContainer>
                    )
                }
                </NotificationsContainer>
            }

            {/* Here show modal notifications */}
            <DeleteNotificationsModal handleGetNotifications={handleGetNotifications} />
        </MainLayout>
    )
}

export default NotificationsView


const NotificationsContainer = styled.div`
    flex-grow:1;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    padding:20px 30px;
`

const NotificationsList = styled.div`
    display:flex;
    flex-direction:column;
    width: 100%;
`


const NotificationEmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const Subtitle = styled.p`
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
    color: #C7C7C7;
`

const NotificationItem = styled.div`
    display: flex;
    /* padding-top: 10px;
    padding-bottom: 10px; */
    align-items: center;
    .indicator{
        /* width:25%; */
        /* background-color: aqua; */
        height:60px;
        display:flex;
        align-items: center;
        justify-content:center;
    }
    .message{
        flex:1;
        padding:0 0px 0 10px;
        font-size:12px;
        line-height:18px;

        .timestamp{
            color: ${colors.eggGreyNotifications} !important
        }
    }
    /* &:not(:last-child){
        border-bottom:1px solid ${colors.separadorLineas}
    } */
`