import { colors } from '../../../style/variables'
import styled from 'styled-components'
import { UserRole } from '../../../interfaces/IWidgetInfo'

export const ListItemStyled = styled.li<{ role: UserRole; isMentorTeam: boolean }>`
    position: relative;
    cursor: pointer;
    padding: 11px 8px;
    margin: 5px 0;
    &:first-child {
        margin-top: 0;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    border-radius: 12px;
    font-weight: ${props => getFontWeight(props.role, props.isMentorTeam)};
    border: ${props => getBorderColor(props.role, props.isMentorTeam)};
    background-color: ${props => getBgColor(props.role, props.isMentorTeam)};
    .user-data {
        display: flex;
        align-items: center;
        flex-basis: 80%;
        &:hover p {
            text-decoration: underline;
        }
        p {
            margin: 0;
            color: ${props => getFontColor(props.role, props.isMentorTeam)};
            letter-spacing: 0.15px;
        }
        .user-item-label {
            margin-left: 10px;
            display: block;
            font-size: 0.68rem;
            color: ${props => getFontColor(props.role, props.isMentorTeam)};
            text-transform: initial;
            font-weight: normal;
        }
    }
`

const getFontWeight = (role: UserRole, isMentorTeam: boolean) => {
    switch (role) {
        case UserRole.MENTOR1:
        case UserRole.MENTOR2:
        case UserRole.EXPERTMENTOR:
            return `600`
        default:
            return 'normal'
    }
}

const getBorderColor = (role: UserRole, isMentorTeam: boolean) => {
    switch (role) {
        case UserRole.MENTOR1:
            return `1px solid ${colors.eggPinkDarker}`
        case UserRole.MENTOR2:
            return `1px solid ${colors.eggPurple}`
        case UserRole.EXPERTMENTOR:
            return `1px solid ${colors.eggDarkPurpleBorders}`
        default:
            return 'none'
    }
}

const getFontColor = (role: UserRole, isMentorTeam: boolean) => {
    switch (role) {
        case UserRole.MENTOR2:
        case UserRole.EXPERTMENTOR:
            return 'white'
        default:
            return colors.primary
    }
}

const getBgColor = (role: UserRole, isMentorTeam: boolean) => {
    switch (role) {
        case UserRole.MENTOR1:
            return colors.eggPink
        case UserRole.MENTOR2:
            return colors.eggPurple
        case UserRole.EXPERTMENTOR:
            return colors.eggDarkPurple
        default:
            return 'white'
    }
}
