import { ButtonPulseStyled } from './ButtonPulseStyled'
import BoltColor from '../../../assets/boltColor.svg'
import { useVoting } from '../../../hooks/useVoting'
import { TablePos, UserRole } from '../../../interfaces/IWidgetInfo'
import { Text } from 'preact-i18n'

interface IBtnPulse {
    role?: UserRole
    item: TablePos
    expanded?: boolean
    onClick?: any
    voted?: boolean
}

const ButtonPulse = ({ item, role, expanded = false, onClick, voted }: IBtnPulse) => {
    
    const { voteStudent, didIVoteThisUser } = useVoting()
    return (
        <ButtonPulseStyled
            onClick={onClick ? () => onClick(item._profileId) : () => voteStudent(item._profileId)}
            role={role}
            aria-label="Give pulse to another student"
            expanded={expanded}
            voted={voted ? voted : didIVoteThisUser(item._profileId)}
        >
            <p className="text">
                {didIVoteThisUser(item._profileId) ? <Text id="actions.recognized"></Text> : <Text id="actions.recognize"></Text>}
            </p>

            <img style={{ height: '18px' }} src={BoltColor} alt="Bolt icon" />
        </ButtonPulseStyled>
    )
}

export default ButtonPulse