import { ComponentChildren } from 'preact'
import HelpRequestModal from '../components/Actions/HelpRequestModal'
import styled, { css } from 'styled-components'

interface IProps {
    centered?: boolean
    started?: boolean
    children: ComponentChildren
}

const MainLayout = ({ children, centered, started }: IProps) => {
    return (
        <>
            <Layout centered={centered} started={started}>
                {children}
            </Layout>
            <HelpRequestModal />
        </>
    )
}

export default MainLayout

const Layout = styled.div<{ centered: boolean; started: boolean }>`
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${({ centered }) =>
        centered &&
        css`
            justify-content: center;
            /* align-items: center; */
        `}
    ${({ started }) =>
        started &&
        css`
            justify-content: flex-start;
        `}
`
