import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { UIContext } from '../../context/UIContext'
import styled from 'styled-components'
import { Notifications, Response } from '../../routes/NotificationsView/NotificationsView'
import { ProgressBar } from './ProgressBar'
import axios from 'axios'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { capitalizeFullName } from '../../helpers/capitalize'
import { Text } from 'preact-i18n'
import { TextButton } from '../Buttons/TextButton'
import { colors } from '../../style/variables'
import AvatarEgg from '../Avatar/AvatarEgg'
import IconPulse from '../../assets/icon_pulso.svg'
import { useLocation, useNavigate } from 'react-router'
import { AppContext } from '../../app'
import { setGAWidgetStorie } from '../../helpers/GAnalytics'
import blurred_bg from '../../assets/blurred_bg.jpg'

export const Stories = () => {
    const { notifications, setShowStories, showStories, setNotifications } = useContext(UIContext)
    const { updateInfo, widgetInfo } = useContext(AppContext)
    const navigate = useNavigate()
    const { search } = useLocation()

    const initalTime = 5
    const percentValue = 100 / initalTime
    const [numberStory, setNumberStory] = useState(0)
    const [timeCurrent, setTimeCurrent] = useState(initalTime)
    const [storie, setStorie] = useState<Notifications | undefined>(undefined)
    const [percent, setPercent] = useState(0)
    const [loading, setLoading] = useState(false)
    const [opacity, setOpacity] = useState(false)
    const [readedStory, setReadedStory] = useState<string[]>([])

    const handleGetNotifications = async () => {
        setLoading(true)
        const { data }: { data: { data: Response } } = await axios.get(`${process.env.PREACT_APP_WIDGET_API}/v1/notification/type/story`)
        const notificationsOrdered = data.data.notifications.sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1))
        setNotifications(notificationsOrdered)
        setLoading(false)

        let indexStory = notificationsOrdered.findIndex(st => st.readStory === false)
        if (indexStory !== -1) {
            setNumberStory(indexStory)
        }
    }
    let timeOut: any

    useEffect(() => {
        handleGetNotifications()
        setOpacity(false)
        // here we register ga
        setGAWidgetStorie()
    }, [])

    useLayoutEffect(() => {
        setStorie(notifications[numberStory])
    }, [notifications, numberStory])

    useLayoutEffect(() => {
        timeOut = setTimeout(() => setTimeCurrent(timeCurrent - 1), 1000)
        timeCurrent > 0 && timeOut

        if (timeCurrent === 0) {
            // setReadedStory(prev => [...prev, storie!._id])
            if (numberStory < notifications.length - 1) {
                handleNextStorie()
                setTimeCurrent(initalTime)
                setPercent(0)
            } else {
                clearTimeout(timeOut)
                handleSaveStorieReaded()
                // this section is for autoclose modal storie
                setOpacity(true)
                setTimeout(() => setShowStories(false), 300)
            }
        } else {
            //agregamos
            if (storie?._id) {
                if (!readedStory.includes(storie?._id)) {
                    setReadedStory(prev => [...prev, storie?._id ?? ''])
                }
            }
        }

        if (percent < 99) {
            setPercent(percent + percentValue)
        }

        return () => {
            clearTimeout(timeOut)
        }
    }, [timeCurrent, numberStory])

    const handleNextStorie = () => {
        setTimeCurrent(initalTime)
        setPercent(0)
        setNumberStory(numberStory + 1)
        setStorie(notifications[numberStory + 1])
        clearTimeout(timeOut)
    }

    const handlePrevStorie = () => {
        setTimeCurrent(initalTime)
        setPercent(0)
        setNumberStory(numberStory - 1)
        setStorie(notifications[numberStory - 1])
        clearTimeout(timeOut)
    }

    const handleSaveStorieReaded = async () => {
        const { data } = await axios.patch(`${process.env.PREACT_APP_WIDGET_API}/v1/notification/readedStory`, {
            notificationsIds: readedStory,
        })

        if (numberStory === notifications.length - 1) [updateInfo({ me: { ...widgetInfo?.me, allReadStories: true } })]
    }

    return (
        <Histories className={opacity && 'hide'}>
            {loading && (
                <div style={{ textAlign: 'center', height: '100%', padding: '0 40px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Text id="control.loading" />
                    </div>
                </div>
            )}
            {!loading && (
                <div style={{ position: 'relative' }}>
                    <ProgressContainer>
                        {notifications.map((_, i) => (
                            <ProgressBar percent={percent} index={i} numberStory={numberStory} />
                        ))}

                        <FontAwesomeIcon
                            className="icon"
                            style={{ marginLeft: 10, color: colors.darkGreyEgg, cursor: 'pointer', fontSize: 22 }}
                            onClick={() => {
                                handleSaveStorieReaded()
                                setOpacity(true)
                                setTimeout(() => setShowStories(false), 400)
                            }}
                            icon={['fal', 'times']}
                        />
                    </ProgressContainer>
                    <HistoryList>
                        <ContainerIcon>
                            {numberStory > 0 && (
                                <FontAwesomeIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handlePrevStorie()}
                                    className="circle-icon icon"
                                    icon={['fal', 'chevron-left']}
                                />
                            )}
                        </ContainerIcon>

                        <History>
                            <ContainerImage>
                                <AvatarEgg title={`${storie?.user.name} ${storie?.user.lastname}`} src={storie?.user.imgUrl ?? ''} size={105} />

                                <Circle>
                                    <img src={IconPulse} alt="bolt pulse" />
                                </Circle>
                            </ContainerImage>

                            <HistoryContainer>
                                <HistoryFullName>
                                    {capitalizeFullName(storie?.user.name + ' ' + storie?.user.lastname ?? '')}
                                    {storie?.user.role.toLocaleLowerCase() === 'facilitator' && (
                                        <Badge className="facilitador">
                                            <FontAwesomeIcon className="icon-role" icon={['fas', 'medal']} />
                                        </Badge>
                                    )}
                                    {storie?.user.role.toLocaleLowerCase() === 'mentor' && (
                                        <Badge className="mentor">
                                            <FontAwesomeIcon className="icon-role" icon={['fas', 'graduation-cap']} />
                                        </Badge>
                                    )}
                                </HistoryFullName>

                                <DescriptionStorie>
                                    {storie?.tableNumber !== null ? (
                                        <Text id={'notifications.pulse-team-storie'}></Text>
                                    ) : (
                                        <Text id={'notifications.single-pulse-storie'}></Text>
                                    )}
                                </DescriptionStorie>
                            </HistoryContainer>
                        </History>
                        <ContainerIcon>
                            {numberStory < notifications.length - 1 && (
                                <FontAwesomeIcon
                                    onClick={() => handleNextStorie()}
                                    style={{ cursor: 'pointer' }}
                                    className="circle-icon icon"
                                    icon={['fal', 'chevron-right']}
                                />
                            )}
                        </ContainerIcon>
                    </HistoryList>

                    <ButtonContainer>
                        <TextButton
                            onClick={() => {
                                setShowStories(false)
                                navigate(`/user/${widgetInfo?.me?._id}` + search)
                            }}
                            style={{ color: '#2B75FF' }}
                            aria-label="Cancel"
                        >
                            <Text id={'notifications.stories.button'}></Text>
                        </TextButton>
                    </ButtonContainer>
                </div>
            )}
        </Histories>
    )
}

const Histories = styled.div`
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px); */
    background-image: url(${blurred_bg});
    background-position: center top;
    background-size: cover;
    .icon:hover {
        opacity: 0.7;
    }

    .icon {
        font-size: 30px !important;
    }

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`

const HistoryList = styled.ul`
 display: flex;
 justify-content: center;
 align-items: center;
 height: 100vh;
 padding: 0;
 margin: 0;
`

const History = styled.li`
    display: flex;
    flex-direction: column;
`


const HistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const HistoryFullName = styled.p`
    font-size: 16;
    font-weight: bold;
    padding: 0;
    margin: 0;
    color: #37474F;
    line-height: 20px;
    display: flex;
    gap: 4px;
    align-items: center;
`


const ProgressContainer = styled.ul`
    display: flex;
    list-style: none;
    align-items: center;
    gap: 10px;
    width: 100%;
    position: absolute;
    padding-left: 60px;
    padding-right: 38px;
    padding-top: 35px;
    margin: 0;
`

const Circle = styled.div`
    position: relative;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: -40px;
    background: transparent linear-gradient(180deg, #FF647C 0%, #00A3FF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #00000019;

    .circle-icon {
        color: white;
        position: absolute;
        left: 31px;
        width: 37.64px;
        height: 56.46px;
        top: 25px;
        
    }
`

const DescriptionStorie = styled.p`
    font-size: 16px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    color: #37474F;
`


const ButtonContainer = styled.div`
    position: absolute;
    bottom: 84px;
    display: flex;
    justify-content: center;
    width: 100%;

`

const ContainerImage = styled.figure`
    display: flex;
    position: relative;
    margin-bottom: 31;
`


const ContainerIcon = styled.div`
    width: 10px;
`

const Badge = styled.span`
    width: 22px;
    height: 22px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-role{
        font-size: 12px;
        color: white;
    }

    &.facilitador {
        background-color: ${colors.eggFacilitators} !important;
    }

    &.mentor {
        background-color: ${colors.eggPurple} !important;
    }
`