import { UserRole } from '../../../interfaces/IWidgetInfo'
import { colors } from '../../../style/variables'
import styled from 'styled-components'

const getBorderColor = (role: UserRole) => {
    switch (role) {
        case UserRole.MENTOR1:
            return colors.eggPinkDarker
        case UserRole.MENTOR2:
            return colors.eggPurpleLigth
        case UserRole.EXPERTMENTOR:
            return colors.eggPurpleLigth
        default:
            return 'white'
    }
}

const getFontColor = (role: UserRole) => {
    switch (role) {
        case UserRole.MENTOR1:
            return colors.primary
        case UserRole.MENTOR2:
            return 'white'
        case UserRole.EXPERTMENTOR:
            return 'white'
        default:
            return 'white'
    }
}

const getBgColor = (role: UserRole, hover?: boolean) => {
    switch (role) {
        case UserRole.MENTOR1:
            return hover ? colors.eggPinkDarker : 'transparent'
        case UserRole.MENTOR2:
            return hover ? colors.eggPurpleLigth : 'transparent'
        case UserRole.EXPERTMENTOR:
            return hover ? colors.eggPurpleLigth : 'transparent'
        default:
            return 'transparent'
    }
}

export const ButtonQuitStyled = styled.button<{ role: UserRole }>`
    display: flex;
    align-items: center;
    outline: none !important;
    justify-content: center;
    background: ${props => getBgColor(props.role)};
    border-radius: 12px;
    font-size: 11px;
    text-align: center;
    font-weight: bold;
    transition: background-color 0.3s ease;
    cursor: pointer;
    height: 40px;
    width: auto;
    padding: 0 10px;
    color: ${props => getFontColor(props.role)};
    border: 1px solid ${props => getBorderColor(props.role)};
    &:hover {
        background: ${props => getBgColor(props.role, true)};
    }
    .icon {
        color: ${props => getFontColor(props.role)};
        margin-right: 0px;
        font-size: 20px;
        width: 26px;
        height: 16px;
        display: inline;
    }
`
