import { useNavigation } from '../../../hooks/useNavigation'
import AvatarEgg from '../../../components/Avatar/AvatarEgg'
import { TablePos, UserRole } from '../../../interfaces/IWidgetInfo'
import { ListItemStyled } from './ListItemStyled'
import { LeadTextBlock } from '../../../components/Type/Title'
import { ellipsis } from '../../../helpers/Tools'
import { Text } from 'preact-i18n'
import ButtonQuit from '../../../components/Buttons/Quit/ButtonQuit'
import ButtonPulse from '../../../components/Buttons/Pulse/ButtonPulse'
import { useContext } from 'preact/hooks'
import { AppContext } from '../../../app'

type Props = {
    item: TablePos
    label?: string
    role: UserRole
    leave?: boolean
    pulse?: boolean
    isMentorTeam?: boolean
    returnMentor: () => void
    onClick?: (_profileId: string) => void
    voted?: boolean
    userLabel: string,
    canRedirect?: boolean
}

export const ListItemNew = ({ item, role, leave, pulse, isMentorTeam, returnMentor, onClick, voted, userLabel, canRedirect }: Props) => {
    const { navigation } = useNavigation()
    const { widgetInfo } = useContext(AppContext)
    const blockButtonQuit = (widgetInfo?.algorithm?.isMentor && widgetInfo.table?.helpRequest?.status === "requested")

    return (
        <ListItemStyled role={role} isMentorTeam={isMentorTeam}>
            <div className="user-data" onClick={ canRedirect ? () => navigation(`/user/${item._userId._id}`): () => {} } aria-label="Visit user profile">
                <AvatarEgg
                    title={`${item._userId.name} ${item._userId.lastname}`}
                    src={item._userId.urlImage}
                    size={38}
                    facilitator={role === UserRole.FACILITATOR}
                    isMentor={item.isMentor || item.isExpertMentor}
                />
                <div>
                    <LeadTextBlock style={{ marginLeft: '10px', fontSize: '13px' }}>
                        <p>{ellipsis(item._userId.name + ' ' + item._userId.lastname, 24)} </p>
                    </LeadTextBlock>
                    <span className="user-item-label">{<Text id={userLabel || ''} />}</span>
                </div>
            </div>
            {leave && <ButtonQuit disabled={blockButtonQuit} isMentor={item.isMentor} role={role} returnMentor={returnMentor} />}
            {pulse && <ButtonPulse item={item} role={role} onClick={onClick} voted={voted} /> }
        </ListItemStyled>
    )
}
