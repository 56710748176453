
interface Props {
    rest?: [x: string],
    className?: string
}

export const HandDownSVG = ({className, ...rest} : Props) => {
  return (
    <svg 
        className={ className }
        { ...rest }
        xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path id="Video_icon" data-name="Video icon" d="M8.5,3.563V16.688A1.312,1.312,0,0,0,9.812,18h4.375A1.312,1.312,0,0,0,15.5,16.688V3.563A1.312,1.312,0,0,0,14.187,2.25H9.812A1.312,1.312,0,0,0,8.5,3.563ZM10.687,14.5A1.312,1.312,0,1,1,12,15.813,1.312,1.312,0,0,1,10.687,14.5Zm14.875,14c1.312,0,3.937-.437,3.937-4.454,0-2.32-1.42-3.621-1.82-5.171h5.563A3.268,3.268,0,0,0,36.5,15.7a3.96,3.96,0,0,0-1.063-2.69L35.431,13a4.569,4.569,0,0,0-.509-4.346,4.325,4.325,0,0,0-.9-4.088,2.9,2.9,0,0,0-.336-2.441C32.573.523,29.807.5,27.468.5h-.156a15.7,15.7,0,0,0-6.538,1.735,8.6,8.6,0,0,1-2.879.885.656.656,0,0,0-.644.656v11.69a.654.654,0,0,0,.195.466c2.166,2.141,3.1,4.407,4.874,6.186a6.623,6.623,0,0,1,1.389,3.221C23.95,26.351,24.459,28.5,25.562,28.5Z" transform="translate(-8.5 -0.5)" fill="currentColor"/>
    </svg>

  )
}
