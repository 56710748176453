import { colors } from '../../style/variables'
import styled from 'styled-components'

export const IconButton = styled.button`
  background: none;
  outline: none;
  border: none;
  font-size: 1.5rem;
  color: ${colors.darkGreyEgg};
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :disabled {
    color: #C7C7C7;
    cursor: not-allowed;
  }
`
