import { useContext, useEffect, useState } from 'preact/hooks'
import { WhiteButton } from '../../components/Buttons/WhiteButton'
import styled from 'styled-components'
import { AppContext } from '../../app'
import MainLayout from '../../layouts/MainLayout'
import { MainTitle, LeadTextBlock } from '../../components/Type/Title'
import { useVoting } from '../../hooks/useVoting'
import ReactGA from 'react-ga4'
import { Text } from 'preact-i18n'
import { ListItemNew } from '../../components/Table/ListItem/ListItem'
import recognizeMentol from '../../assets/recognize_mentor.png'
import { UIContext } from '../../context/UIContext'
import { useNavigation } from '../../hooks/useNavigation'
import { TablePos, UserRole } from '../../interfaces/IWidgetInfo'

const MandatoryVoteMentorView = () => {
    const { navigation } = useNavigation()
    const { widgetInfo, mentorToVote } = useContext(AppContext)
    const { setShowRecognizeMentor } = useContext(UIContext)
    const [membersToVote, setMembersToVote] = useState<string[]>([])
    const { voteStudent } = useVoting()

    useEffect(() => {
        ReactGA.gtag({
            page_path: '/mandatory-vote-mentor', 
            modal_name: 'mandatory-vote-mentor-modal'
        })
    }, [])

    const givePulse = async (algorithmId: string) => {
        membersToVote.forEach(element => {
            voteStudent(element, algorithmId, true, true)
        })

        setShowRecognizeMentor(false)
        navigation('/')
    }

    const handleCloseMandatoryMentorView = () => {
        setShowRecognizeMentor(false)
        navigation('/')
    }

    const handlePulse = (id: string) => {
        if (membersToVote.includes(id)) {
            setMembersToVote(prev => [...prev.filter(item => item !== id)])
        } else {
            setMembersToVote(prev => [...prev, id])
        }
    }

    const getUserLabel = (item: TablePos) => {
        if (item.isMentor && !item.isExpertMentor) {
            return 'roles.mentor'
        }
        if (item.isExpertMentor) {
            return 'roles.expertMentor'
        }
        return ''
    }

    return (
        <MainLayout>
            <Resume>
                <img
                    className="img-mandatory-mentor"
                    src={recognizeMentol}
                    style={{ width: 110, height: 110, objectFit: 'cover', marginBottom: '23.5px', marginTop: '44.5px' }}
                    alt=""
                />
                <MainTitle style={{ marginTop: '10px', textAlign: 'center', fontSize: '16px', marginBottom: '10px' }}>
                    <Text id={'mandatory_vote_mentor.title'}>Reconoce</Text>
                </MainTitle>
                <LeadTextBlock
                    style={{
                        margin: '0px 0px 10px 0px',
                        textAlign: 'center',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#37474F',
                        fontWeight: 300,
                    }}
                >
                    <Text id={'mandatory_vote_mentor.subtitle'}>
                        Si el conocimiento compartido por los mentores/as te sirvió para despejar dudas, puedes reconocerles dándoles un pulso.
                    </Text>
                </LeadTextBlock>
                <VoteList className="vote-list-mandatory-mentor">
                    {mentorToVote?.mentorToVote?.map(dent => {
                        return (
                            <ListItemNew
                                canRedirect={false}
                                item={dent}
                                key={dent._profileId}
                                role={UserRole.STUDENT}
                                isMentorTeam={false}
                                returnMentor={() => null}
                                pulse={true}
                                onClick={handlePulse}
                                voted={membersToVote.includes(dent._profileId)}
                                userLabel={getUserLabel(dent)}
                            />
                        )
                    })}
                </VoteList>

                <div className='container-action'
                >
                    {membersToVote.length > 0 ? (
                        <BlueButtonWithGray
                            className="join-button"
                            disabled={membersToVote.length === 0}
                            onClick={
                                membersToVote.length > 0 &&
                                (() => {
                                    givePulse(widgetInfo?.algorithm?._id || '')
                                    // setGAWidgetMandatoryVoteMentorClicked()
                                })
                            }
                            aria-label="Join now"
                        >
                            Confirmar
                        </BlueButtonWithGray>
                    ) : (
                        <TextButton
                            className="join-button"
                            onClick={() => handleCloseMandatoryMentorView()}
                            aria-label="Cancel"
                        >
                            <Text id={'mandatory_vote_mentor.omit'}>Omitir</Text>
                        </TextButton>
                    )}
                </div>
            </Resume>
        </MainLayout>
    )
}

export default MandatoryVoteMentorView

const Resume = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 30px;

    .img-mandatory-mentor {
        display: block !important;
    }

    @media screen and (max-height: 480px) {
        .img-mandatory-mentor {
            display: none !important;
        }
    }

    .container-action {
        width: 100%;
        position: fixed;
        bottom: 0;
        padding: 10px 30px 20px 30px;
        background: white;
        z-index: 10;
    }

    .vote-list-mandatory-mentor {
        margin-top: 5px;
        margin-right: 5px;
    }
`

export const BlueButtonWithGray = styled(WhiteButton)`
    background-color: #4c9aff;
    color: white;
    margin-top: 10px;
    width: 100%; 
    font-size: 0.7rem;

    &:disabled {
        background-color: #eaeaea !important;
        color: #c7c7c7 !important;
    }
    &:hover:enabled {
        background-color: #105df9 !important;
        color: white !important;
    }
`
const TextButton = styled(WhiteButton)`
    color: #296efa;
    border: none;
    box-shadow: none;
    margin-top: 10px;
    width: 100%; 
    font-size: 0.7rem;

    :hover {
        text-decoration: underline;
        color: #296efa !important;
        background-color: white !important;
    }
`

export const VoteList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    padding-bottom: 70px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`
