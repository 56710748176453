import { colors } from '../../style/variables'
import styled from 'styled-components'

export const TextButton = styled.button`
    border: none;
    outline: none;
    color: ${colors.blueEgg};
    background-color: transparent;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    margin-top: 16px;
    display: block;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    a {
        color: ${colors.blueEgg};
    }
`
