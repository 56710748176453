import styled from "styled-components";

export const StyledIconBell = styled.div`
cursor: pointer;

    .notifications {
        width: 5px;
        height: 5px;
        color: #FF7F7F;
        position: absolute;
        margin-left: -7px;
        margin-top: 1px;
        border: 2px solid white;
        border-radius: 50%;
        background-color: white;
    }

    &:hover {
        color: #2B75FF !important;
    }

`

