import React from 'react'
import styled from 'styled-components'
import confirmation from '../../assets/feedback/check.png'
import { SecondaryVoteButtonShadow } from './CommentsFeedback'
import { UIContext } from '../../context/UIContext'
import { useContext } from 'preact/hooks'


export const ConfirmationFeedback = () => {

    const { setShowFeedback } = useContext(UIContext)

  return (
    <ContainerConfirmation>
       <div style={{paddingBottom: 43}}>
           <img style={{ width: 147, height: 80}} src={confirmation} alt="" />
       </div>
       <div style={{ textAlign: 'center' , paddingBottom: 111}}>
           <TextTitle>Comentario enviado</TextTitle>
           <TextDescription>Utilizaremos la información para seguir mejorando tu experiencia futura.</TextDescription>
       </div>

       <div style={{  width: '100%' }}>
        <SecondaryVoteButtonShadow 
            onClick={ () => setShowFeedback(false) }
            style={{ marginBottom: 19 }}>
            Entendido
        </SecondaryVoteButtonShadow>
       </div>
    </ContainerConfirmation>
  )
}



const ContainerConfirmation = styled.div`
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 43px;
    padding-right: 43px;
`

const TextTitle = styled.h2`
    font-size: 16px;
    padding: 0;
    padding-bottom: 7px;
    margin: 0;

`

const TextDescription = styled.p`
    font-size: 13px;
    color: #8E8E8E;
    line-height: 20px;
    padding: 0;
    margin: 0;
`