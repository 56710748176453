import React, {useState} from 'react'
import { HAND, IOptions, OPTIONS } from '../../routes/FeedbackView/FeedbackView'
import styled from 'styled-components'
import { SecondaryVoteButton } from '../Buttons/SecondaryVoteButton'
import axios from 'axios'
import { FEEDBACK } from '../../contants/contants'
import { AppContext } from '../../app'
import { useContext, useLayoutEffect } from 'preact/hooks'
import { Text } from 'preact-i18n'
import { setGAWidgetFeedbackSentComment } from '../../helpers/GAnalytics'
import { NavigationIcon } from '../Actions/HelpRequestModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BlueButtonWithGray } from '../../routes/MandatoryVoteMentorView/MandatoryVoteMentorView'
import { NotificationType, UIContext } from '../../context/UIContext'
import { colors } from '../../style/variables'
// import { Text } from 'preact-i18n';


interface Props {
    option: IOptions,
    setOption: (arg: IOptions) => void
}

type body = {
    type: string;
    comments?: string;
    isSatisfied: boolean; 
    expirationDateFeedback: Date;
    preTabOptions: string[] | undefined
};

const limitValue = 200;

export const CommentsFeedback = ({ option, setOption }: Props) => {

    const { widgetInfo, updateInfo } = useContext(AppContext)
    const { setShowFeedback, dispatchNotification } = useContext(UIContext)
    const [countValue,setCountValue] = useState(limitValue)
    const [ valueSelected, setValueSelected] = useState("")
    const [ disabledBtn, setDisabledBtn] = useState(false)


    const [optionsTabs, setOptionsTabs] = useState([
        {
            value: 'Ayuda de mentores',
            component: () => <Text id='feedback.options.mentor-help' ></Text>
        },
        {
            value: 'El coach',
            component: () => <Text id='feedback.options.the-coach' ></Text>
        },
        {
            value: 'Mi equipo del día',
            component: () => <Text id='feedback.options.my-team-of-the-day' ></Text>
        },
        {
            value: 'Los ejercicios',
            component: () => <Text id='feedback.options.the-exercises' ></Text>
        },
        {
            value: 'La teoría',
            component: () => <Text id='feedback.options.the-theory' ></Text>
        },
        {
            value: 'Otro',
            component: () => <Text id='feedback.options.other' ></Text>
        }
    ])

    useLayoutEffect(() => {
        if(widgetInfo?.algorithm?.isMentor){
            setOptionsTabs([ {
                value: 'Ser mentor',
                component: () => <Text id='feedback.options.be-mentor'></Text>
            } , ...optionsTabs])
        }
    }, [])

    

    const handleChangeValue = (e: any) => {
        let valueText = e.target.value;
        if(valueText.length > limitValue){
            return;
        }
        setOption({
            descriprion: valueText,
            page: option.page,
            selection: option.selection
        })
        setCountValue( limitValue - valueText.length )
    }

    const handleSelectedItem = (item: string) => {
        if(valueSelected === item){
            setValueSelected("")
        }else{
            setValueSelected(item)
        }
       
    }

    const handleSaveInformation = async ({ sendOptions = false }) => {

        setDisabledBtn(true)

        const body: body = {
            type: FEEDBACK.COURSE,
            isSatisfied: option.selection === HAND.UP ? true : false,
            comments: option.descriprion,
            expirationDateFeedback: new Date(),
            preTabOptions: sendOptions ? [ valueSelected.toLowerCase() ] : undefined
        }  
        try {

            const result = await axios.post(`${process.env.PREACT_APP_WIDGET_API}/v1/feedback/stack-feedback`, JSON.stringify(body),{
                headers:{
                    'Content-Type': 'application/json'
                }
            })
           
                setOption({
                    descriprion: '',
                    page: OPTIONS.CONFIRMATION,
                    selection: ''
                })

                updateInfo({ me: { ...widgetInfo?.me, shouldGiveFeedback: false } })
                handleCloseFeedback()

                if(sendOptions){
                    dispatchNotification({ type: NotificationType.SUCCESS, payload: {
                        title: 'Respuesta enviada',
                        subtitle: 'Gracias por compartir tu experiencia.'
                    }})
                }
           
        } catch (error) {
            console.error(error)
        }
    }

    const handleCloseFeedback = () => {
        setOption({
            descriprion: '',
            page: OPTIONS.SELECTOR,
            selection: ''
        })

        setShowFeedback(false)
    }

    const handleGoBackFeedback = () => {
        setOption({
            descriprion: '',
            page: OPTIONS.SELECTOR,
            selection: ''
        })

    }

  return (
    <ContainerComments>

        <NavigationIcon
            style={{ marginLeft: 'auto', left: 10, right: 'auto' }}
            onClick={handleGoBackFeedback}
        >
            <FontAwesomeIcon icon={['fal', 'arrow-left']}  />
        </NavigationIcon>

        <NavigationIcon
            style={{ marginRight: 'auto' }}
            onClick={handleSaveInformation}
        >
            <FontAwesomeIcon icon={['fal', 'times']} />
        </NavigationIcon>
        <TextTitle>
            {
                option.selection == HAND.UP
                    ? <Text id={"feedback.commentsFeedback.title.hand-up"}>¡Gracias!</Text>
                    : <Text id={"feedback.commentsFeedback.title.hand-down"}>Lo sentimos</Text>
            }
        </TextTitle>

        <div style={{ paddingLeft: 43, paddingRight: 43, marginBottom: 29 }}>

            <TextDescription>
                {
                    option.selection == HAND.UP
                        ? <Text id={"feedback.commentsFeedback.description.hand-up"}>Por favor, cuéntanos acerca de las cosas que más te gustaron de este encuentro.</Text>
                        : <Text id={"feedback.commentsFeedback.description.hand-down"}>¿Qué fue lo que menos te gustó de este encuentro?</Text>
                }   
            </TextDescription>

        </div>

        <BadgeContainer>
            {
                optionsTabs.map((item, i) => (
                    
                    <BadgeItem 
                    handUp={ option.selection === HAND.UP } 
                    className={
                        valueSelected === item.value ? 'selected': ''
                    } onClick={() => handleSelectedItem(item.value)} key={item.value} >
                        { item.component() }
                    </BadgeItem>
                ))
            }  
        </BadgeContainer>

        
        <div style={{ paddingLeft: 21, paddingRight: 21, width: '100%'}}>

            
        <TextTitle style={{ fontSize: 14, paddingTop:32, paddingBottom:11 }}>
            <Text id='feedback.commentsFeedback.something-more'></Text>
        </TextTitle>
 
            

            <div style={{ position: 'relative', marginBottom: 23}}>
                <TextArea 
                    maxLength={limitValue}
                    value={ option.descriprion }
                    onChange={ handleChangeValue }
                ></TextArea>
                <span style={{
                    position: 'absolute',
                    bottom: 15,
                    right: 15,
                    fontSize: 11,
                    color: countValue === 0 ? colors.salmon: colors.primary700,
                }}>Quedan { countValue } caracteres</span>
            </div>

               
            <BlueButtonWithGray  onClick={ () => { handleSaveInformation({ sendOptions: true });setGAWidgetFeedbackSentComment() } } disabled={valueSelected==="" || disabledBtn} style={{ marginBottom: 19, width: '100%', opacity: 1}}>
                <Text id={"feedback.commentsFeedback.buttons.sendComment"}> Enviar comentario </Text>
            </BlueButtonWithGray>

        </div>
    </ContainerComments>
  )
}




const ContainerComments = styled.div`
    overflow: auto;

    @media only screen and (max-height : 350px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @media only screen and (max-height : 550px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
`

const TextTitle = styled.h2`
    text-align: center;
    font-size: 17px;
    padding: 0;
    color: ${ colors.primary700 };
    padding-bottom: 6px;
    margin: 0;
`

const TextDescription = styled.p`
    font-size: 14px;
    color: ${ colors.primary700 };
    line-height: 22px;
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: 500;
`

const TextArea = styled.textarea`
    width: 100%;
    height: 140px;
    color: ${ colors.primary700 };
    font-size: 13px;
    padding: 15px;
    border-radius: 8px;
    resize: none;
    border:  '1px solid gray';

    &:focus{
        outline: none;
        border: '1px solid gray' !important;
    }

    @media only screen and (max-height : 350px) {
        height: 100px !important;
    }

    @media only screen and (max-height : 550px) {
        height: 150px !important;
    }

`

export const SecondaryVoteButtonShadow = styled(SecondaryVoteButton)`
    box-shadow: 0px 0px 30px #0000000D;
    height: 36px;
    &:disabled{
        cursor: not-allowed !important;
        color: #CFD6DC !important;
        background-color: #F4F4F4 !important;
    }

    &:hover:enabled{
        background-color: #0000000D !important;
        cursor: pointer !important;
        color: black !important;
        border: #EAEAEA !important;
    }

`


const BadgeContainer = styled.ul`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 10px;
    padding: 0;
    column-gap: 10px;
`
const BadgeItem = styled.li`
    padding: 6px 20px;
    display: flex;
    background: #F2F2F2;
    color: ${ colors.primary700 };
    border-radius: 20px;
    font-size: 13px;
    line-height: 20px;
    cursor: pointer;

    &.selected {
        background: ${ (props) => props.handUp ? colors.green700 : colors.salmon};
        color: white !important
    }

`