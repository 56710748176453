interface Props {
    rest?: [x: string],
    className?: string,
    style?: Record<string, any>
}


export const IconHROff = ({ className, style, ...rest }:Props) => {
  return (
    <svg className={ className } style={style} { ...rest } xmlns="http://www.w3.org/2000/svg" width="15.75" height="18" viewBox="0 0 15.75 18">
     <path id="Bullhorn_icon" data-name="Bullhorn icon" d="M15.375,12.625h-.812a6.152,6.152,0,0,1-5.126,0H8.625a4.5,4.5,0,0,0-4.5,4.5A1.126,1.126,0,0,0,5.25,18.25h13.5a1.126,1.126,0,0,0,1.125-1.125A4.5,4.5,0,0,0,15.375,12.625Zm-9.562-4.5a.563.563,0,0,0,.562-.562V7a5.625,5.625,0,1,1,11.25,0v.562a2.817,2.817,0,0,1-2.812,2.812H13.687A1.126,1.126,0,0,0,12.562,9.25H11.437a1.125,1.125,0,1,0,0,2.25h3.375A3.944,3.944,0,0,0,18.75,7.563V7A6.75,6.75,0,0,0,5.25,7v.562A.563.563,0,0,0,5.812,8.125Zm5.625,0h1.125A2.246,2.246,0,0,1,14.51,9.25h.3A1.69,1.69,0,0,0,16.5,7.563V7a4.5,4.5,0,1,0-7.3,3.5c0-.041-.012-.08-.012-.122A2.254,2.254,0,0,1,11.437,8.125Z" transform="translate(-4.125 -0.25)" fill="currentColor"/>
    </svg>

  )
}
