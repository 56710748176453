import { CircleButton } from '../Buttons/CircleButton'
import questionIcon from '../../assets/feedback/dialog.png'
import styled from 'styled-components'
import { HAND, IOptions } from '../../routes/FeedbackView/FeedbackView'
import { Text } from 'preact-i18n'
import { setGAWidgetFeedbackSelectorHandDown, setGAWidgetFeedbackSelectorHandUp } from '../../helpers/GAnalytics'


interface Props {
    setOption: (arg: IOptions) => void
}

export const SelectorFeedback = ({ setOption }: Props) => {
  return (
    <ContainerFeedback>
       <div style={{paddingBottom: 54}}>
           <img style={{ width: 147, height: 80}} src={questionIcon} alt="" />
       </div>
       <div style={{ textAlign: 'center' , paddingBottom: 48}}>
            <TextTitle>
               <Text id={"feedback.selectorFeedback.title"}>Califica el encuentro</Text>
            </TextTitle>
            <TextDescription>
                <Text id={"feedback.selectorFeedback.description"}>El encuentro ha llegado a su fin. ¿Cómo calificarías la experiencia?</Text>
            </TextDescription>
       </div>

       <div style={{ gap: '29px', display: 'flex' }}>
           <CircleButton
                onClick={ () =>  {    
                    setGAWidgetFeedbackSelectorHandDown();
                    setOption({
                        page: 'comments',
                        selection: HAND.DOWN,
                        descriprion: ''
                    });

                
            }}
                showIcon
                handDown
            ></CircleButton>
           <CircleButton
                onClick={ () =>  {
                    setGAWidgetFeedbackSelectorHandUp();
                    setOption({
                        page: 'comments',
                        selection: HAND.UP,
                        descriprion: ''
                    });
                }
            }
                showIcon
                handup
           ></CircleButton>
       </div>
    </ContainerFeedback>
  )
}


const ContainerFeedback = styled.div`
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 43px;
    padding-right: 43px;
`

const TextTitle = styled.h2`
    font-size: 16px;
    padding: 0;
    padding-bottom: 6px;
    margin: 0;

`

const TextDescription = styled.p`
    width: 230px;
    font-size: 13px;
    color: #8E8E8E;
    line-height: 20px;
    padding: 0;
    margin: 0;
`