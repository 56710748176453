import styled, { css } from 'styled-components';
import { colors } from '../../style/variables'

export const SecondaryVoteButton = styled.button`
    display:flex;
    outline: none;
    align-items:center;
    justify-content:center;
    background: #FFFFFF;
    /* box-shadow: 0 0 30px 0 rgba(0,0,0,0.05); */
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    /* text-transform: uppercase; */
    font-size: 12px;
    color: #37474F;
    letter-spacing: 0.05rem;
    text-align: center;
    font-weight:bold;
    transition: background-color .3s ease;
    height:50px;
    width:100%;
    
    &:hover:enabled{
        background-color:${colors.darkGreyEgg};
        color:white;
        cursor:pointer;
        .icon{
            color:white;
        }
    }
    .icon{
        color: ${colors.eggColor};
        margin-right:10px;
        font-size:20px;
        display:inline;
    }
    ${({ voted }) => voted && css`
        background: ${colors.darkGreyEgg};
        color: white;
        .icon{
            color:white;
        }
    `}



    ${({ shadow }) => shadow && css`
        box-shadow: 0px 0px 30px #0000000D;
    `}
`