import { useContext } from "preact/hooks"
import { NotificationType, UIContext } from "../../context/UIContext"
import styled from "styled-components"
import moveMentorToTableImg from '../../assets/moveToTeam.png'
import { BlueButton } from '../../components/Buttons/BlueButton'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const MoveMentorToTableTeam = () => {
    const { dispatchNotification } = useContext(UIContext)
    const {  setMoveMentorToTable, moveMentorToTable } = useContext(UIContext)

    
    const renderScreen = () => {

        if(moveMentorToTable?.payload?.eventName === 'mentor-has-joined' && moveMentorToTable.payload?.meetingSwitchType === 'url'){
            return <>
                <Title>Llegarás al Equipo { moveMentorToTable?.payload?.tableNumber }</Title>
                <Description>
                    Al ingresar, deberás abandonar la reunión actual y unirte a la nueva. Allí encontrarás al <strong>Equipo { moveMentorToTable?.payload?.tableNumber }</strong>  para comenzar tu mentoría. ¡Adelante!
                </Description>
                <BlueButton
                    onClick={
                        () =>  {
                            window.open(`${moveMentorToTable.payload?.meetingUrl}`)
                             setMoveMentorToTable(undefined),
                            dispatchNotification({
                                type: NotificationType.SUCCESS,
                                payload: {
                                    icon: <FontAwesomeIcon className='icon-styled' icon={['fal', 'map-pin']} />,
                                    title: '¡Bienvenido!',
                                    subtitle: `Estás en el Equipo ${ moveMentorToTable?.payload?.tableNumber }`,
                                },
                            })
                        }
                    }
                    style={{width: '100%', borderRadius: 12}}
                    className="join-button"
                    aria-label="Join now"
                >
                    Ingresar
                </BlueButton>
            </>
        }

        if(moveMentorToTable?.payload?.eventName === 'ui-team-has-updated' && moveMentorToTable.payload?.meetingSwitchType === 'url'){
            return <>
                <Title>Regresarás al Equipo de Mentores</Title>
                <Description style={{width:200}} >
                    Al ingresar, deberás abandonar la reunión actual y unirte a la nueva. Allí encontrarás al <strong>Equipo { moveMentorToTable?.payload?.tableNumber }</strong> de Mentores
                </Description>
                <BlueButton
                    onClick={()=> {
                        window.open(`${moveMentorToTable.payload?.meetingUrl}`)
                         setMoveMentorToTable(undefined)}
                    }
                    style={{width: '100%', borderRadius: 12}}
                    className="join-button"
                    aria-label="Join now"
                >
                    Ingresar
                </BlueButton>
            </>
        }

        if(moveMentorToTable?.payload?.eventName === 'mentor-has-joined' && moveMentorToTable.payload?.meetingSwitchType === 'breakout'){
            return <>
                <Title>Llegarás al Equipo { moveMentorToTable?.payload?.tableNumber }</Title>
                <Description>
                    Al ingresar, únete al <strong>Equipo { moveMentorToTable?.payload?.tableNumber } </strong> del encuentro en vivo para comenzar tu mentoría. ¡Adelante!
                </Description>
                <BlueButton
                    onClick={()=> {
                        
                         setMoveMentorToTable(undefined)
                        dispatchNotification({
                            type: NotificationType.SUCCESS,
                            payload: {
                                icon: <FontAwesomeIcon className='icon-styled' icon={['fal', 'map-pin']} />,
                                title: '¡Bienvenido!',
                                subtitle: `Estás en el Equipo ${ moveMentorToTable?.payload?.tableNumber }`,
                            },
                        })
                        }
                    }
                    style={{width: '100%', borderRadius: 12}}
                    className="join-button"
                    aria-label="Join now"
                >
                    Ingresar
                </BlueButton>
            </>
        }

        if(moveMentorToTable?.payload?.eventName === 'ui-team-has-updated' && moveMentorToTable.payload?.meetingSwitchType === 'breakout'){
            return <>
                <Title style={{width: 222}}>Regresarás al Equipo de Mentores</Title>
                <Description>
                    Al ingresar, únete al <strong>Equipo { moveMentorToTable.payload.tableNumber } Mentores</strong> del encuentro en vivo para conectarte con las personas de tu equipo.
                </Description>
                <BlueButton
                    onClick={()=> {
                         setMoveMentorToTable(undefined)
                    }}
                    style={{width: '100%', borderRadius: 12}}
                    className="join-button"
                    aria-label="Join now"
                >
                    Ingresar
                </BlueButton>
            </>
        }
    }

  return (
    <Layout>
        <img width={217} src={moveMentorToTableImg} />
        <Container>{ renderScreen() }</Container>
    </Layout>
  )
}

const Layout = styled.div`
    position: fixed;
    background-color: white;
    height: 100%;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const Title = styled.h2`
    margin: 0;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    color: #003750;
    text-align: center;
    margin-bottom: 19px;
`

const Description = styled.p`
     margin: 0;
    font-size: 12px;
    line-height: 19px;
    color: #003750;
    text-align: center;
    margin-bottom: 37px;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 217px;
    justify-content: center;
    align-items: center;
    margin-top: 99px;
`

