
import Modal from 'react-modal'
import { UIContext } from '../../context/UIContext'
import { useContext } from 'preact/hooks'
import { ModalContainer, modalStyles, NavigationIcon, Paragraph } from './HelpRequestModal'
import bell from '../../assets/notifications/bell.png'
import { SecondaryTitle } from '../Type/Title'
import { Text } from 'preact-i18n'
import { TextButton } from '../Buttons/TextButton'
import { WhiteButton } from '../Buttons/WhiteButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { AppContext } from '../../app'

export const DeleteNotificationsModal = ({ handleGetNotifications }: { handleGetNotifications: () => void }) => {
  const { showDeleteNotifications, setShowDeleteNotifications } = useContext(UIContext)
  const { widgetInfo, updateInfo } = useContext(AppContext)

  const handleDelete = async () => {
    await axios.delete(`${process.env.PREACT_APP_WIDGET_API}/v1/notification/delete-vote-notifications`)
    setShowDeleteNotifications(false)
    handleGetNotifications()
    
    updateInfo({ me: { ...widgetInfo?.me, hasNotifications: false }})
  }

  return (
    <Modal isOpen={!!showDeleteNotifications} style={modalStyles} >
        <ModalContainer>
            <img style={{ width: 100, height: 100 }} src={ bell } alt="image bell" />
            <SecondaryTitle style={{marginBottom:'10px'}}>
                <Text id={ 'notifications.delete.title' }>Eliminar Notificaciones</Text>
            </SecondaryTitle>
            <Paragraph>
                <Text id={ 'notifications.delete.subtitle' }>Borra de forma permanentes todas las notificaciones recibidas hasta el momento.</Text>
            </Paragraph>
            
            <WhiteButton style={{ marginBottom: 15 }} width={100} onClick={ () => handleDelete() }>
                <Text id={ 'notifications.delete.btn-delete' }>Eliminar</Text>
            </WhiteButton>

            <TextButton onClick={ () => setShowDeleteNotifications(false) }>
                <Text id={ 'notifications.delete.btn-cancel' }>Cancelar</Text>
            </TextButton>

            <NavigationIcon
                style={{ marginLeft: 'auto' }}
                onClick={() => setShowDeleteNotifications(undefined)}
            >
                <FontAwesomeIcon icon={['fal', 'times']} />
            </NavigationIcon>
            
        </ModalContainer>
    </Modal>
  )
}
