import { colors } from '../../style/variables';
import styled from 'styled-components';
import { WhiteButton } from './WhiteButton';

export const BlueButton = styled(WhiteButton)`
    background-color: ${colors.blueEgg};
    color: white;
    font-size: 12px;
    line-height: 23px;
    font-weight: 600;
    width: 100%;
    &:hover {
        background-color: #048edb !important;
        color: white !important;
    }
`