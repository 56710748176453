import styled from 'styled-components'
import { MainTitle } from '../Type/Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '../../components/Buttons/IconButton'
import { UIContext } from '../../context/UIContext'
import { useContext } from 'preact/hooks'
import { useNavigation } from '../../hooks/useNavigation'

interface IProps {
    name?: string | JSX.Element
}


export const NotificationsHeading = ({ name }: IProps) => {
    const { setShowDeleteNotifications, notifications } = useContext(UIContext)
    const { navigation } = useNavigation()

    return (
        <Heading>
            <IconButton aria-label="Go back" onClick={() => navigation('/')} className="left">
                <FontAwesomeIcon style={{ width:'18px', height:'21px'}} icon={['fal', 'arrow-left']} />
            </IconButton>
            <MainTitle className="middle">{ name }</MainTitle>
            <IconButton disabled={ notifications.length === 0 } aria-label="Disabled and hidden button" onClick={ () => setShowDeleteNotifications(true) } className="right">
                <FontAwesomeIcon style={{ width: 16, height: 18 }} icon={['fal', 'trash']} />
            </IconButton>
        </Heading>
    )
}



const Heading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 21px;
    margin-top: 23px;
    height: 35px;

    .left {
        width: 15%;
    }
    .middle {
        width: 70%;
        font-size: 12px;
        font-weight: bold;
        padding: 0 20px;
        text-transform: capitalize;
        line-height: 1.6rem;
        text-align: center;
    }
    .right {
        width: 15%;
    }
`