
import styled from 'styled-components'
import { HandUpSVG } from '../../assets/feedback/HandUp'
import { HandDownSVG } from '../../assets/feedback/HandDown'


interface Props {
    showIcon: boolean,
    handDown?: boolean,
    handup?: boolean,
    onClick?: () => void
}


export const CircleButton = ({ showIcon, handDown, onClick }: Props) => {
  return (
    <ContainerButton onClick={ onClick }>
        {
            showIcon && handDown ? <HandDownSVG  className="icon-hand-down"/> : <HandUpSVG className='icon-hand-up' /> 
        }
    </ContainerButton>
  )
}


const ContainerButton = styled.button`
    border: 1px #dadada solid;
    outline: none;
    width: 66px;
    height: 66px;
    border-radius: 100%;
    background-color: white;
    box-shadow: 0px 0px 20px #0000000D;
    cursor: pointer;

    &:hover .icon-hand-down {
        color: #FF647C;
    }

    &:hover .icon-hand-up {
        color: #6ADDAC;
    }

    &:hover {
        background-color: #EAEAEA;
    }


`