import dayjs from 'dayjs'
import { IWidgetInfo, TablePos, UserRole } from '../interfaces/IWidgetInfo'
type MyPermissionName = PermissionName | 'clipboard-read' | 'clipboard-write'

export const requestPermission = async (permission: MyPermissionName) => {
    return await navigator.permissions.query({ name: permission as PermissionName })
}

export const ellipsis = (valueString: string, length: number) => {
    if (valueString.length <= length || valueString === null) {
        return valueString
    }

    return valueString.substring(0, length) + '...'
}

export const getElapsedTime = (initialValue: Date) => {
    return dayjs(initialValue).fromNow()
}

export const isWithinFiveMinutes = (date: string) => {
    const dateObj = dayjs(date)
    const now = dayjs()
    const fiveMinutes = 5 * 60 * 1000
    return dateObj.isBetween(now.subtract(fiveMinutes, 'milliseconds'), now.add(fiveMinutes, 'milliseconds'))
}

export const getUserLabel = (item: TablePos, widgetInfo?: IWidgetInfo) => {
    if (!widgetInfo) return ''

    const { table } = widgetInfo

    if (table?.facilitator === item._profileId && isAllowedToBeFacilitator(widgetInfo)) {
        return 'roles.facilitator'
    }
    if (item.isMentor && !item.isExpertMentor) {
        return 'roles.mentor'
    }
    if (item.isExpertMentor) {
        return 'roles.expertMentor'
    }
    return ''
}

// Allows facilitators only after start day
export const isAllowedToBeFacilitator = (widgetInfo?: IWidgetInfo) =>
    !!widgetInfo && dayjs(widgetInfo.team.startDate?.date).startOf('day').isBefore(dayjs().startOf('day'))

export const getItemRole = (item: TablePos, widgetInfo?: IWidgetInfo) => {
    if (!widgetInfo) return UserRole.STUDENT

    const { table } = widgetInfo

    if (table?.isMentorTeam && !table?.isExpertMentorTeam) {
        if (item.isExpertMentor) {
            return UserRole.MENTOR1
        }
    }
    if (table?.isMentorTeam || table?.isExpertMentorTeam) {
        if (item.isMentor || item.isExpertMentor) {
            return UserRole.STUDENT
        }
        switch (item.hrLevel) {
            case 1:
                return UserRole.MENTOR1
            case 2:
                return UserRole.MENTOR2
            case 3:
                return UserRole.EXPERTMENTOR
        }
        return UserRole.STUDENT
    } else {
        if (table?.facilitator === item._profileId) {
            if (isAllowedToBeFacilitator(widgetInfo)) {
                return UserRole.FACILITATOR
            }
        }
        switch (item.hrLevel) {
            case 1:
                return UserRole.MENTOR1
            case 2:
                return UserRole.MENTOR2
            case 3:
                return UserRole.EXPERTMENTOR
        }
        return UserRole.STUDENT
    }
}