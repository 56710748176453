import { Link, useLocation } from 'react-router-dom';

interface IProps {
    children: JSX.Element | string,
    to: {
        pathname:string,
        query?:string
    } | string
}

export const LinkWithQuery = ({ children, to, ...props }: IProps) => {
  const { search } = useLocation();

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
};