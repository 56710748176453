import { useState, useRef, useEffect, useContext } from 'preact/hooks'
import { AppContext } from '../../app'
import MainLayout from '../../layouts/MainLayout'
import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import { MainTitle, TextBlock } from '../../components/Type/Title'
import { WhiteButton } from '../../components/Buttons/WhiteButton'
import Step1 from '../../assets/wizard/step1.png'
import Step2 from '../../assets/wizard/step2.png'
import Step3 from '../../assets/wizard/step3.png'
import Step4 from '../../assets/wizard/step4.png'
import Step5 from '../../assets/wizard/step5.png'
import Step6 from '../../assets/wizard/step6.png'
import Step7 from '../../assets/wizard/step7.png'
import Step8 from '../../assets/wizard/step8.png'
import BadgeMentores from '../../assets/wizard/badge_mentores.png'
import { colors } from '../../style/variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Text, MarkupText } from 'preact-i18n'
import ReactGA from 'react-ga4'
import Axios from 'axios'
import { useNavigation } from '../../hooks/useNavigation'

const Tutorial = () => {
    const { navigation } = useNavigation()
    const [step, setStep] = useState(1)
    const [mode, setMode] = useState<string | undefined>(undefined)
    const onboarding = useRef<Slider>(null)
    const { widgetInfo, updateInfo } = useContext(AppContext)

    useEffect(() => {
        // Get page from url
        const path = window.location.pathname.split('/')
        const mode = path[2]
        setMode(mode)
        ReactGA.event({
            category: 'Help',
            action: 'TutorialClicked',
            label: 'methodology',
        })
    }, [])

    const handleSlideChange = (current: number) => {
        setStep(current + 1)
    }

    const settings = {
        dots: false,
        infinite: false,
        initialSlide: 0,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        draggable:false,
        onInit: () => setStep(1),
        afterChange: (current: number) => handleSlideChange(current),
    }

    const next = async () => {
        switch(mode){
            case 'student': 
                if (step === tutorialStudents.length) {
                    return completeTutorial('student')
                } else {
                    return onboarding?.current?.slickNext()
                }
            case 'pulses':
                if (step === tutorialPulses.length) {
                    return completeTutorial('pulses')
                } else {
                    return onboarding?.current?.slickNext()
                }
            case 'mentor':
                if (step === tutorialMentores.length) {
                    return navigation('/')
                } else {
                    return  onboarding?.current?.slickNext()
                }
        }
    }

    const shouldShowPrev = () => {
        if (step !== 0) {
            return true
        } else {
            return false
        }
    }

    const completeTutorial = async (tutorial: typeof mode) => {
        // Lógica para completar el tutorial
        try {
            if(tutorial === 'student' && widgetInfo?.me.tutorialWidgetCompleted === false){
                const res = await Axios.patch<any>(`${process.env.PREACT_APP_WIDGET_API}/v1/user/completedTutorial`,{ value: true })
            }
            if(tutorial === 'pulses' && widgetInfo?.me.tutorialPulsesCompleted === false){
                const res = await Axios.patch<any>(`${process.env.PREACT_APP_WIDGET_API}/v1/user/completedPulses`,{ value: true })
            }
        } catch (error) {
            console.log('Error patching.', error)
        } finally {
            updateInfo({ me: { ...widgetInfo?.me, tutorialWidgetCompleted: true}})
            navigation('/')
        }
    }

    useEffect(() => {
        shouldShowPrev()
    }, [step])

    const renderModal = () => {
        switch(mode) {
            case 'student':
                return(
                    tutorialStudents.map(slide => {
                        return (
                            <SliderItem>
                                <div className="img-container">
                                    <img src={slide.img} alt="" />
                                </div>
                                <div className="text-content">
                                    <MainTitle>{slide.title}</MainTitle>
                                    <TextBlock style={{ fontSize: '15px' }} center>
                                        {slide.desc}
                                    </TextBlock>
                                </div>
                                <div className="next-container">
                                    <WhiteButton
                                        disabled={step === 1}
                                        onClick={() => onboarding?.current?.slickPrev()}
                                        value={step}
                                    >
                                        <Text id="tutorial.prev" />
                                    </WhiteButton>
                                    <WhiteButton 
                                    onClick={() => next()} 
                                    value={step}
                                    bgColor={step === tutorialStudents.length ? '#00A3FF' : null}
                                    >
                                        {step === tutorialStudents.length ? (
                                            <Text id="tutorial.start" />
                                        ) : (
                                            <Text id="tutorial.next" />
                                        )}
                                    </WhiteButton>
                                </div>
                            </SliderItem>
                        )
                    })
                )
            case 'mentor': 
                return(
                    tutorialMentores.map(slide => {
                        return (
                            <SliderItem>
                                <div className="img-container">
                                    <img src={slide.img} alt="" />
                                </div>
                                <div className="text-content">
                                    <MainTitle>{slide.title}</MainTitle>
                                    <TextBlock style={{ fontSize: '15px' }} center>
                                        {slide.desc}
                                    </TextBlock>
                                </div>
                                <div className="next-container">
                                    <WhiteButton disabled={step === 1} onClick={() => onboarding?.current?.slickPrev()} value={step}>
                                        <Text id="tutorial.prev" />
                                    </WhiteButton>
                                    <WhiteButton onClick={() => next()} value={step} bgColor={step === tutorialMentores.length ? '#00A3FF' : null}>
                                        {step === tutorialMentores.length ? <Text id="tutorial.start" /> : <Text id="tutorial.next" />}
                                    </WhiteButton>
                                </div>
                            </SliderItem>
                        )
                    })
                )
            case 'pulses':
                return(
                    tutorialPulses.map(slide => {
                        return (
                            <SliderItem bgColor={step === 1 ? '#BFC7D3' : 'white'}>
                                <div>
                                <div className="img-container">
                                    <img src={slide.img} alt="" />
                                </div>
                                <div className="text-content">
                                    {slide?.lead && <Lead>{slide.lead}</Lead>}
                                    <MainTitle>{slide.title}</MainTitle>
                                    <TextBlock style={{ fontSize: '15px' }} center>
                                        {slide.desc}
                                    </TextBlock>
                                </div>
                                <div className="next-container">
                                    <WhiteButton
                                        disabled={step === 1}
                                        onClick={() => onboarding?.current?.slickPrev()}
                                        value={step}
                                    >
                                        <Text id="tutorial.prev" />
                                    </WhiteButton>
                                    <WhiteButton 
                                    onClick={() => next()} 
                                    value={step}
                                    bgColor={step === tutorialPulses.length ? '#00A3FF' : null}
                                    >
                                        {step === tutorialPulses.length ? (
                                            <Text id="tutorial.start" />
                                        ) : (
                                            <Text id="tutorial.next" />
                                        )}
                                    </WhiteButton>
                                </div>
                                </div>
                            </SliderItem>
                        )
                    })
                )
        }
    }

    return (
        <MainLayout>
            <TutorialContainer>
                <SliderContainer>
                    {mode && (
                        <Slider {...settings} ref={onboarding}>
                            {renderModal()}
                        </Slider>
                    )}
                </SliderContainer>
            </TutorialContainer>
            <TutorialNavigation>
                {
                    <NavigationIcon
                        style={{ marginLeft: 'auto' }}
                        onClick={() => completeTutorial(mode === 'student' ? 'student' : mode === 'pulses' ? 'pulses' : 'mentor')}
                    >
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </NavigationIcon>
                }
            </TutorialNavigation>
        </MainLayout>
    )
}

export default Tutorial

const Lead = styled.span`
    text-transform: uppercase;
    color:white;
    font-size:.8rem;
`

const TutorialNavigation = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
`

const NavigationIcon = styled.button`
    font-size: 22px;
    color: ${colors.darkGreyEgg};
    background: none;
    border: none;
    &:hover {
        opacity: 0.7;
    }
    cursor: pointer;
`

const TutorialContainer = styled.div`
    /* background-color:aquamarine; */
    height: 100%;
    padding: 0;
    position: relative;
`

const SliderItem = styled.div`
    /* background-color:blue; */
    height: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 30px;
    ${(props) => props.bgColor && css`
        background-color: ${props.bgColor};
    `}
    /* @media screen and (max-height: 400px) { */
        justify-content: center;
    /* } */
    .img-container {
        display: flex; 
        align-items: center;
        flex: 1;
        width: 100%;
        display:none;
        img{
            
            width:100%;
        }
        @media screen and (min-height: 450px) {
            display:block;
            img{
                
            }
        }
        /* @media screen and (min-height: 500px) {
            img {
                display:block;
            }
        } */
        /* @media screen and (max-height: 600px) { */
            /* img {
                width: 100%;
            } */
        /* } */
    }
    .text-content {
        @media screen and (min-height: 500px) {
            min-height: 200px !important;
        }
        padding: 15px 0;
        p {
            color: ${colors.darkGreyEgg};
        }
    }
    .next-container {
        display: flex;
        width: 95%;
        button {
            width: 50%;
            margin: 4px;
            box-shadow: none;
            text-transform:uppercase;
            font-size:.65rem;
        }
    }
    .pagination-container {
        width: 100%;
        text-align: right;
        margin-right: 20px;
        font-weight: bold;
        margin-top: 5px;
        font-size: 12px;
    }
`

const SliderContainer = styled.div`
    height: 100%;
    .slick-slider {
        height: 100%;
    }
    .slick-slide > div {
        /* background-color:brown; */
        height: 100%;
    }
    .slick-list {
        height: 100%;
    }
    .slick-track {
        height: 100%;
    }
    .slick-dots {
        /* background-color:yellow; */
        margin-bottom: 25px;
        li {
            padding: 0;
            margin: 0;
            button {
                height: auto;
            }
        }
        li button:before,
        li.slick-active button:before {
            color: transparent;
            opacity: 1;
        }
        li button:before {
            background-color: #e8e9ea;
            border: 2px solid #e8e9ea;
            border-radius: 50%;
            height: 5px;
            width: 5px;
        }
        li.slick-active button:before {
            border: 2px solid #37474f;
            background-color: #37474f;
        }
    }
`

const tutorialStudents = [
    {
        img: Step1,
        title: <MarkupText id="tutorial.students.step0.title" />,
        desc: <MarkupText id="tutorial.students.step0.msg" />,
    },
    {
        img: Step2,
        title: <MarkupText id="tutorial.students.step1.title" />,
        desc: <MarkupText id="tutorial.students.step1.msg" />,
    },
    {
        img: Step3,
        title: <MarkupText id="tutorial.students.step2.title" />,
        desc: <MarkupText id="tutorial.students.step2.msg" />,
    },
    {
        img: Step4,
        title: <MarkupText id="tutorial.students.step3.title" />,
        desc: <MarkupText id="tutorial.students.step3.msg" />,
    },
    {
        img: Step5,
        title: <MarkupText id="tutorial.students.step4.title" />,
        desc: <MarkupText id="tutorial.students.step4.msg" />,
    }
]

const tutorialMentores = [
    // {
    //     img: Step0,
    //     title: <MarkupText id="tutorial.mentores.step0.title" />,
    //     desc: <MarkupText id="tutorial.mentores.step0.msg" />,
    // },
    {
        img: BadgeMentores,
        title: <MarkupText id="tutorial.mentores.step1.title" />,
        desc: <MarkupText id="tutorial.mentores.step1.msg" />,
    },
    {
        img: BadgeMentores,
        title: <MarkupText id="tutorial.mentores.step2.title" />,
        desc: <MarkupText id="tutorial.mentores.step2.msg" />,
    },
]

const tutorialPulses = [
    {
        lead: <MarkupText id="tutorial.pulses-announcement.step0.lead" />,
        img: Step6,
        title: <MarkupText id="tutorial.pulses-announcement.step0.title" />,
        desc: <MarkupText id="tutorial.pulses-announcement.step0.msg" />,
    },
    {
        lead: '',
        img: Step7,
        title: <MarkupText id="tutorial.pulses-announcement.step1.title" />,
        desc: <MarkupText id="tutorial.pulses-announcement.step1.msg" />,
    },
    {
        lead: '',
        img: Step8,
        title: <MarkupText id="tutorial.pulses-announcement.step2.title" />,
        desc: <MarkupText id="tutorial.pulses-announcement.step2.msg" />,
    },
]
