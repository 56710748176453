import React from 'react'
interface Props {
    rest?: [x: string],
    className?: string,
    style?: Record<string, any>
}

export const IconHROn = ({ className, style, ...rest }:Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.875" height="19" viewBox="0 0 18.875 19">
        <g id="Grupo_98" data-name="Grupo 98" transform="translate(-10071.625 -2872.75)">
            <path id="Sustracción_2" data-name="Sustracción 2" d="M14.625,19.25H1.125A1.126,1.126,0,0,1,0,18.125a4.505,4.505,0,0,1,4.5-4.5h.812a6.172,6.172,0,0,0,2.569.559,6.063,6.063,0,0,0,2.558-.559h.812a4.505,4.505,0,0,1,4.5,4.5A1.126,1.126,0,0,1,14.625,19.25ZM10.688,12.5H7.313a1.125,1.125,0,0,1,0-2.25H8.438a1.126,1.126,0,0,1,1.125,1.125h1.125a2.826,2.826,0,0,0,2.559-1.647,4.98,4.98,0,0,0,1.116.245A3.97,3.97,0,0,1,10.688,12.5Zm-5.612-1h0a4.49,4.49,0,0,1,4.9-7.477,5.037,5.037,0,0,0-.1.983,4.975,4.975,0,0,0,2.356,4.244,1.692,1.692,0,0,1-1.543,1.006h-.3A2.255,2.255,0,0,0,8.438,9.125H7.313a2.255,2.255,0,0,0-2.25,2.25.5.5,0,0,0,.007.071c0,.016,0,.032.006.049ZM1.688,9.125a.563.563,0,0,1-.562-.562V8a6.758,6.758,0,0,1,6.75-6.75,6.668,6.668,0,0,1,3.025.716,5,5,0,0,0-.58.968,5.559,5.559,0,0,0-2.445-.56A5.631,5.631,0,0,0,2.25,8v.563A.563.563,0,0,1,1.688,9.125Z" transform="translate(10072.125 2872)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Sustracción_1" data-name="Sustracción 1" d="M5,8.5A3.5,3.5,0,1,1,8.5,5,3.5,3.5,0,0,1,5,8.5Z" transform="translate(10082 2872)" fill="#ff647c"/>
        </g>
    </svg>

  )
}
