import { useLocation, useNavigate } from 'react-router'

interface Navigation {
    navigation: (stringPath: string) => void
}

export const useNavigation = () : Navigation => {
    const navigate = useNavigate()
    const { search } = useLocation()

    const navigation = (stringPath: string) => {
        navigate(stringPath + search)
    }

    return {
        navigation,
    }
}
