import React, { useContext, useState } from 'react'
// import { UIContext } from '../context/UIContext'
import styled, { css } from 'styled-components'
import { ToastType } from '../context/UIContext'
import { Text } from 'preact-i18n'
interface IProps {
    toast: ToastType
}


const InfoBlock = ({toast}:IProps) => {
    return (
        <Info toast={{type:toast[0], msg:toast[1]}}>
            {/* {toast[1]} */}
            {toast[0] === 'error' ? toast[1] || <Text id="actions.error"></Text> : null} 
        </Info>
    )
}
export default InfoBlock

const Info = styled.div<{toast: {type:string, msg: string}}>`
    position: fixed;
    top: 0;
    left: 0;
    padding: 0px 20px;
    min-height: 80px;
    width: 100%;
    font-size: .8rem;
    /* transition: all 0.3s ease;
    transform: translateY(-100%); */
    text-align: center;
    color: white;
    font-weight: bold;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(0%);
    transition: all 0.3s ease;
    ${({ toast }) => toast && css`
        background-color: ${toast.type === 'error' ? 'rgba(253, 115, 115, 0.9)' : 'rgba(139, 237, 154, 0.9)'};
    `}
`
