import React from 'react'
import Modal from 'react-modal'
import { UIContext } from '../../context/UIContext'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import { ModalContainer, modalStyles, NavigationIcon, Paragraph } from './HelpRequestModal'
import img_link_zoom from '../../assets/zoom/img_link_zoom.png'
import { SecondaryTitle } from '../Type/Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WhiteButton } from '../Buttons/WhiteButton'
import { TextButton } from '../Buttons/TextButton'
import axios from 'axios'
import { Text } from 'preact-i18n'
import useCopy from 'use-copy'
import { setGAWidgetZAppCode } from '../../helpers/GAnalytics'


export const ZoomGetCodeModal = () => {

    const { showZoomCode, setShowZoomCode } = useContext(UIContext)
    const [ codeZoom, setCodeZoom ] = useState('')
    const [copied, copy ] = useCopy(codeZoom);

    const [ copyText, setCopyText ] = useState({
      isCopy: false,
      textValue: ''
  })

  const handleGenerateCodeZoom = async () => {

    try {
        const result = await axios.get(`${process.env.PREACT_APP_WIDGET_API}/v1/zoom/get-code`,{
            headers:{
                'Content-Type': 'application/json'
            }
        })

        if(result.status === 200){
            const { data } = result;
            setCodeZoom( data.data.linkCode );
        }
    } catch (error) {
        console.error(error)
    }
  }

  const handleCopyText = async () => {
    try {
        copy()
        setCopyText({
                isCopy: true,
                textValue: 'Código copiado'
        })
        setGAWidgetZAppCode()
       
    } catch (error) {
        console.log(error)
    }
  }

  useEffect(() => {
    if(showZoomCode){
        handleGenerateCodeZoom()
    }

    setCopyText(
    {
        isCopy: false,
        textValue: 'Copiar código'
    })

  }, [showZoomCode])
  

  return (
    <Modal isOpen={!!showZoomCode} style={modalStyles} >
        <ModalContainer>
            <img src={img_link_zoom} alt="" />
            <SecondaryTitle style={{marginBottom:'10px'}}>
                <Text id="link-zoom.title">Vincular Egg a Zoom</Text>
            </SecondaryTitle>
            <Paragraph>
                <Text id="link-zoom.description">Busca e Instala la Zapp de Egg en Zoom. Copia el siguiente código y pégalo al iniciarla.</Text>
            </Paragraph>
            
            <SecondaryTitle style={{marginBottom:'10px', marginTop: '15px',fontSize: 40, textTransform: 'uppercase' }} id="code-zoom">
                { codeZoom }
            </SecondaryTitle>
           
            <TextButton style={{ marginBottom: '20px' ,color: copyText.isCopy ? '#62D553' : 'none', fontSize: '12px', fontWeight: 600 }} onClick={() => handleCopyText()} >
               {
                   !copyText.isCopy 
                    ? <Text id="link-zoom.buttons.copy-code">Copiar codigo</Text>
                    :   <div style={{ display: 'flex', gap: 4, justifyContent: 'center', alignItems: 'center' }}>
                            <Text id="link-zoom.buttons.copied-code">Código copiado</Text>
                            <FontAwesomeIcon icon={['fas','check']} />
                        </div>
               } 
               
            </TextButton>
            <WhiteButton onClick={() => setShowZoomCode(undefined)} width={100}>
                <Text id="link-zoom.buttons.ok">Entendido</Text>
                </WhiteButton>


            <NavigationIcon
                style={{ marginLeft: 'auto' }}
                onClick={() => setShowZoomCode(undefined)}
            >
                <FontAwesomeIcon icon={['fal', 'times']} />
            </NavigationIcon>
        </ModalContainer>
    </Modal>
  )
}
