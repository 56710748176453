interface Props {
    rest?: [x: string],
    className?: string
}

export const HandUpSVG = ({className, ...rest} : Props) => {
  return (
    <svg 
        className={ className }
        { ...rest }
        xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill='none'>
     <path id="Video_icon" data-name="Video icon" d="M14.187,12.75H9.812A1.312,1.312,0,0,0,8.5,14.063V27.187A1.312,1.312,0,0,0,9.812,28.5h4.375A1.312,1.312,0,0,0,15.5,27.187V14.063A1.312,1.312,0,0,0,14.187,12.75ZM12,26.312A1.312,1.312,0,1,1,13.312,25,1.312,1.312,0,0,1,12,26.312ZM29.5,4.954C29.5.938,26.875.5,25.562.5c-1.1,0-1.612,2.149-1.856,3.161a6.623,6.623,0,0,1-1.389,3.221c-1.776,1.779-2.707,4.045-4.874,6.186a.656.656,0,0,0-.195.466v11.69a.656.656,0,0,0,.644.656,8.606,8.606,0,0,1,2.879.885A15.7,15.7,0,0,0,27.312,28.5h.156c2.339,0,5.106-.023,6.222-1.626a2.9,2.9,0,0,0,.336-2.441,4.324,4.324,0,0,0,.9-4.088A4.568,4.568,0,0,0,35.431,16l.006-.006A3.959,3.959,0,0,0,36.5,13.3a3.268,3.268,0,0,0-3.257-3.177H27.68C28.08,8.575,29.5,7.274,29.5,4.954Z" transform="translate(-8.5 -0.5)" fill="currentColor"/>
    </svg>
  )
}
